import { verifyGuard } from '@core/guard/verify.guard';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import {authGuard} from '@core/guard/auth.guard';
import { loginGuard } from '@core/guard/login.guard';
import { PageErrorServerComponent } from '@page/errors/page-error-server/page-error-server.component';
import { PageErrorNotfoundComponent } from '@page/errors/page-error-notfound/page-error-notfound.component';
import {resetPwGuard} from "@core/guard/reset-pw.guard";

const routes: Routes = [
  {
    path: '',
    loadChildren: () =>
      import('./pages/biz/biz.module').then((m) => m.BizModule),
    canActivate: [authGuard],
  },
  {
    path: 'flows',
    loadChildren: () =>
      import('./pages/biz-preview/biz-preview.module').then(
        (m) => m.BizPreviewModule
      ),
    canActivate: [authGuard],
  },
  {
    path: 'login',
    loadChildren: () =>
      import('./pages/signin/login.module').then((m) => m.LoginModule),
    canActivate: [loginGuard],
  },
  {
    path: 'profile',
    loadChildren: () =>
      import('./pages/profile/profile.module').then((m) => m.ProfileModule),
    canActivate: [authGuard],
  },
  {
    path: 'verify-email',
    loadChildren: () =>
      import('./pages/verify-email/verify-email.module').then(
        (m) => m.VerifyEmailModule
      ),
    canActivate: [verifyGuard],
  },
  {
    path: 'reset-password',
    loadChildren: () =>
      import('./pages/reset-password/reset-password.module').then(
        (m) => m.ResetPasswordModule
      ),
    canActivate: [resetPwGuard],
  },
  {
    path: 'news',
    loadChildren: () =>
      import('./pages/news/news.module').then((m) => m.NewsModule),
    canActivate: [authGuard],
  },
  {
    path: 'templates',
    loadChildren: () =>
      import('./pages/templates/templates.module').then(
        (m) => m.TemplatesModule
      ),
    canActivate: [],
  },
  {
    path: 'connected',
    loadChildren: () =>
      import('./pages/connected/connected.module').then(
        (m) => m.ConnectedModule
      ),
  },
  {
    path: 'user',
    loadChildren: () =>
      import('./pages/user/user.module').then((m) => m.UserModule),
  },
  {
    path: 'server-error',
    component: PageErrorServerComponent,
  },
  {
    path: '**',
    component: PageErrorNotfoundComponent,
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
