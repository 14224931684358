export type LanguageKey = keyof typeof LANGUAGE;

export enum LANGUAGE {
    vi = 'vi',
    en = 'en'
}

export enum LOCALSTORAGE {
    ACTIVE_BIZ = 'active_biz',
    LANGUAGE = 'smaxai_lang',
    IS_COLLAPSE = 'is_collapse',
    ACTIVE_ID_PAGE_TRIGGER = 'active_id_page_trigger'
}
