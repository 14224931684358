<div class="modal-body text-center flex-center flex-column p-4 gap-2">
  <div
    class="d-flex flex-center p-3 mb-3"
    style="width: 70px; height: 70px; border-radius: 50%"
    [ngClass]="
      'bg-' +
      dataConfirm[type].color +
      '-50' +
      ' ' +
      'text-' +
      dataConfirm[type].color +
      '-600'
    "
  >
    <app-icon-custom
      class="fs-v30"
      [name]="dataConfirm[type].icon"
    ></app-icon-custom>
  </div>
  <div class="d-flex flex-column justify-content-center">
    <span class="confirm-title">{{
      title ?? "confirm.title.are_you_sure_delete" | translate
    }}</span>
    <span class="confirm-content heading-5">
      <span
        *ngIf="text"
        [innerHTML]="
          text ??
          (type === 'ERROR'
            ? ('confirm.sub_title.are_you_sure_delete' | translate)
            : '')
        "
      ></span>
      <div *ngIf="contentText" [innerHTML]="contentText"></div>
    </span>
  </div>
  <div class="d-flex justify-content-end gap-2 pt-4 w-100">
    <button
      type="button"
      class="btn btn-light"
      *ngIf="textCancel || type === 'ERROR'"
      (click)="close()"
    >
      {{
        textCancel ??
          (type === "ERROR" ? ("common.button.cancelText" | translate) : "")
      }}
    </button>
    <button
      type="button"
      class="btn text-white"
      [ngClass]="'btn-' + dataConfirm[type].buttontype"
      *ngIf="textConfirm || type === 'ERROR'"
      (click)="confirm()"
    >
      {{
        textConfirm ??
          (type === "ERROR" ? ("common.button.delete" | translate) : "")
      }}
    </button>
  </div>
</div>
