import { Injectable } from '@angular/core';
import { BaseApiService } from '../base.service';
import { HttpClient } from '@angular/common/http';
import { IPage } from 'app/models/channel';
import { BizService } from './biz.service';
import { BlocksService } from './blocks.service';
import { BehaviorSubject, distinctUntilChanged } from 'rxjs';
import { IGroup } from 'app/models/group';
import { IMessageTemplate } from 'app/models/messageTemplate';
import { IBlockTemplate } from 'app/models/block';
import { ITag } from 'app/models/tag';

@Injectable({
  providedIn: 'root',
})
export class SetupDataService {
  public listGroupSubject = new BehaviorSubject<IGroup[]>([]);

  public listMessageTemplateSubject = new BehaviorSubject<IMessageTemplate[]>(
    []
  );

  public listBlockTemplateSubject = new BehaviorSubject<IBlockTemplate[]>([]);

  public listTagSubject = new BehaviorSubject<ITag[]>([]);

  constructor(
    // private: BizService,
    private blocksService: BlocksService
  ) {}

  setUpDataTag(data: IPage[]) {
    if (data.length > 0 && this.listTagSubject.getValue().length > 0) {
      data.forEach((item) => {
        switch (item.use_tags?.type) {
          case 'CUSTOM':
            item.listTag = this.listTagSubject
              .getValue()
              .filter(
                (tag) =>
                  item?.use_tags?.aliases?.includes(tag.alias) && tag.active
              );
            break;
          case 'PAGE':
            let pageTarget: IPage | undefined = item;
            let isDone = false;
            while (!isDone) {
              pageTarget = data.find(
                (page) => page.pid === pageTarget?.use_tags?.page_pid
              );
              if (pageTarget) {
                if (pageTarget.use_tags?.type === 'PAGE') {
                  isDone = false;
                } else {
                  isDone = true;
                }
              } else {
                isDone = true;
              }
            }
            switch (pageTarget?.use_tags?.type) {
              case 'CUSTOM':
                item.listTag = this.listTagSubject
                  .getValue()
                  .filter(
                    (tag) =>
                      pageTarget?.use_tags?.aliases?.includes(tag.alias) &&
                      tag.active
                  );
                break;
              default:
                item.listTag = this.listTagSubject
                  .getValue()
                  .filter((tag) => tag.active);
                break;
            }
            break;
          default:
            item.listTag = this.listTagSubject
              .getValue()
              .filter((tag) => tag.active);
            break;
        }
      });
    }
  }

  setUpDataMessageTeamplate(data: IPage[]) {
    if (
      data.length > 0 &&
      this.listMessageTemplateSubject.getValue().length > 0
    ) {
      data.forEach((item) => {
        switch (item.use_message_templates?.type) {
          case 'CUSTOM':
            item.listMessageTemplate = this.listMessageTemplateSubject
              .getValue()
              .filter(
                (message) =>
                  item?.use_message_templates?.ids?.includes(message.id!) &&
                  message.active
              );
            break;
          case 'PAGE':
            let pageTarget: IPage | undefined = item;
            let isDone = false;
            while (!isDone) {
              pageTarget = data.find(
                (page) =>
                  page.pid === pageTarget?.use_message_templates?.page_pid
              );
              if (pageTarget) {
                if (pageTarget.use_message_templates?.type === 'PAGE') {
                  isDone = false;
                } else {
                  isDone = true;
                }
              } else {
                isDone = true;
              }
            }
            switch (pageTarget?.use_message_templates?.type) {
              case 'CUSTOM':
                item.listMessageTemplate = this.listMessageTemplateSubject
                  .getValue()
                  .filter(
                    (message) =>
                      pageTarget?.use_message_templates?.ids?.includes(
                        message.id!
                      ) && message.active
                  );
                break;
              default:
                item.listMessageTemplate = this.listMessageTemplateSubject
                  .getValue()
                  .filter((message) => message.active);
                break;
            }
            break;
          default:
            item.listMessageTemplate = this.listMessageTemplateSubject
              .getValue()
              .filter((message) => message.active);
            break;
        }
      });
    }
  }

  setUpDataBlock() {
    if (
      this.listMessageTemplateSubject.getValue().length > 0 &&
      this.blocksService.listBlockSubject.getValue().length > 0
    ) {
      const listMessageTemplate = [
        ...this.listMessageTemplateSubject.getValue(),
      ];
      listMessageTemplate.forEach((blockTemplate) => {
        blockTemplate.block = this.blocksService.listBlockSubject
          .getValue()
          .find((item) => item.id === blockTemplate.block_id);
      });
      this.listMessageTemplateSubject.next(listMessageTemplate);
    }
  }

  setUpDataBlockBlock() {
    if (
      this.listBlockTemplateSubject.getValue().length > 0 &&
      this.blocksService.listBlockSubject.getValue().length > 0
    ) {
      const listBlockTemplate = [...this.listBlockTemplateSubject.getValue()];
      listBlockTemplate.forEach((blockTemplate) => {
        blockTemplate.block = this.blocksService.listBlockSubject
          .getValue()
          .find((item) => item.id === blockTemplate.block_id);
      });
      this.listBlockTemplateSubject.next(listBlockTemplate);
    }
  }

  setUpDataBlockTeamplate(data: IPage[]) {
    if (
      data.length > 0 &&
      this.listBlockTemplateSubject.getValue().length > 0 &&
      this.blocksService.listBlockSubject.getValue().length > 0
    ) {
      data.forEach((item) => {
        switch (item.use_block_templates?.type) {
          case 'CUSTOM':
            item.listBlockTemplate = this.listBlockTemplateSubject
              .getValue()
              .filter(
                (block) =>
                  item?.use_block_templates?.ids?.includes(block.id!) &&
                  block.active
              );
            break;
          case 'PAGE':
            let pageTarget: IPage | undefined = item;
            let isDone = false;
            while (!isDone) {
              pageTarget = data.find(
                (page) => page.pid === pageTarget?.use_block_templates?.page_pid
              );
              if (pageTarget) {
                if (pageTarget.use_block_templates?.type === 'PAGE') {
                  isDone = false;
                } else {
                  isDone = true;
                }
              } else {
                isDone = true;
              }
            }
            switch (pageTarget?.use_block_templates?.type) {
              case 'CUSTOM':
                item.listBlockTemplate = this.listBlockTemplateSubject
                  .getValue()
                  .filter(
                    (block) =>
                      pageTarget?.use_block_templates?.ids?.includes(
                        block.id!
                      ) && block.active
                  );
                break;
              default:
                item.listBlockTemplate = this.listBlockTemplateSubject
                  .getValue()
                  .filter((block) => block.active);
                break;
            }
            break;
          default:
            item.listBlockTemplate = this.listBlockTemplateSubject
              .getValue()
              .filter((block) => block.active);
            break;
        }
      });
    }
  }

  setUpDataDistribution(data: IPage[]) {
    if (data.length > 0) {
      data.forEach((item) => {
        switch (item.distribution?.type) {
          case 'PAGE':
            let pageTarget: IPage | undefined = item;
            pageTarget = data.find(
              (page) => page.pid === pageTarget?.distribution?.page_pid
            );
            item.distribution.config = pageTarget?.distribution?.config ?? {};
            break;
        }
      });
    }
  }

  setUpDataCapi(data: IPage[]) {
    if (data.length > 0) {
      data.forEach((item) => {
        switch (item.capi?.type) {
          case 'PAGE':
            let pageTarget: IPage | undefined = item;
            pageTarget = data.find(
              (page) => page.pid === pageTarget?.capi?.page_pid
            );
            item.capi.config = pageTarget?.capi?.config ?? {};
            break;
        }
      });
    }
  }

  setUpDataGroupWeightTag(groups = this.listGroupSubject.getValue()) {
    const tags = this.listTagSubject.getValue();
    if (tags.length && groups?.length) {
      tags.forEach((item) => {
        const group = groups.find((group) => group.id === item.group_id);
        item.group_weight = group?.weight;
      });
    }

    tags.sort((a, b) => {
      if (a.group_weight !== b.group_weight) {
        return (a.group_weight ?? 0) - (b.group_weight ?? 0);
      }
      return a.weight! - b.weight!;
    });

    this.listTagSubject.next(tags);
  }

  setUpDataGroupWeightMessage(groups?: IGroup[]) {
    const messageTemplate = this.listMessageTemplateSubject.getValue();
    if (messageTemplate.length && groups?.length) {
      messageTemplate.forEach((item) => {
        const group = groups.find((group) => group.id === item.group_id);
        item.group_weight = group?.weight;
      });
    }
    messageTemplate.sort((a, b) => {
      if (a.group_weight !== b.group_weight) {
        return (a.group_weight ?? 0) - (b.group_weight ?? 0);
      }
      return a.weight! - b.weight!;
    });
    this.listMessageTemplateSubject.next(messageTemplate);
  }

  setUpDataGroupWeighBlock(groups?: IGroup[]) {
    const blockTemplate = this.listBlockTemplateSubject.getValue();
    if (blockTemplate.length && groups?.length) {
      blockTemplate.forEach((item) => {
        const group = groups.find((group) => group.id === item.group_id);
        item.group_weight = group?.weight;
      });
    }

    blockTemplate.sort((a, b) => {
      if (a.group_weight !== b.group_weight) {
        return (a.group_weight ?? 0) - (a.group_weight ?? 0);
      }
      return a.weight! - b.weight!;
    });
    this.listBlockTemplateSubject.next(blockTemplate);
  }

  // autoUpdateWeightTag(tags: ITag[]) {
  //   tags.forEach(tag => {
  //     const dataCheck = tags.filter(item => item.id !== tag.id).map(item => item.weight)
  //     const currentBiz = this.currentBizSubject.getValue()
  //     const randomNumber = Math.floor(Math.random() * (100000 - 10000 + 1)) + 10000;
  //     if ((dataCheck.includes(tag.weight) || !tag.weight) && currentBiz) {
  //       this.tag.update(currentBiz.alias, tag.id , { weight: Date.now() - randomNumber}).subscribe()
  //     }
  //   })
  // }
}
