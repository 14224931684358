import { Component, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-confirm',
  templateUrl: './confirm.component.html',
  styleUrls: ['./confirm.component.scss'],
})
export class ConfirmComponent implements OnInit {
  confirmed?: boolean;
  title?: string;
  text?: string;
  icon?: string;
  textConfirm?: string;
  textCancel?: string;
  contentText?: string;
  type: 'SUCCESS' | 'ERROR' | 'INFO' | 'WARNING' = 'INFO';

  dataConfirm = {
    SUCCESS: {
      icon: 'circle_check',
      color: 'green',
      buttontype: 'success',
    },
    ERROR: {
      icon: 'delete',
      color: 'red',
      buttontype: 'danger',
    },
    INFO: {
      icon: 'default',
      color: 'secondary',
      buttontype: 'secondary',
    },
    WARNING: {
      icon: 'warning',
      color: 'warning',
      buttontype: 'warning',
    },
  };

  constructor(private modalRef: BsModalRef) {}

  ngOnInit(): void {
    this.modalRef.setClass('modal-dialog-centered');
  }

  close() {
    this.modalRef.hide();
  }

  confirm() {
    this.confirmed = true;
    this.close();
  }
}
