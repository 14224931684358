import { EventEmitter, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BaseApiService } from '../base.service';
import { IResponseWrapper } from '@core/models';
import { IBiz, IBizUser, IBizUserRole, ITypeExceedsPackage } from '../../models/biz';
import { BehaviorSubject, distinctUntilChanged } from 'rxjs';
import { LOCALSTORAGE } from '@core/constants/constant';
import { SessionService } from '../session.service';
import { tap } from 'rxjs/operators';
import { IUser } from '../../models/users';
import { IDomain } from 'app/models/domain';
import { BizGlobalService } from './biz-global.service';
import { ISentCard } from 'app/models/log-card';
import { Router } from '@angular/router';
import {
  LisMenuDefaultChat,
  ListMenuDefaultAds,
  ListMenuDefaultBot,
  ListMenuDefaultRemarketing,
  menuList,
} from '@core/constants/menu';
import { SetupDataService } from './setup-data.services';
import * as dayjs from 'dayjs';

@Injectable({
  providedIn: 'root',
})
export class BizService extends BaseApiService {
  public currentBizSubject = new BehaviorSubject<IBiz | undefined | null>(null);
  public currentBiz = this.currentBizSubject.asObservable().pipe(distinctUntilChanged());

  // biz được chọn tạm thời (trường hợp tạo biz chưa set current biz, thì dùng bizTmp để gọi api connect channel)
  public bizTmpSubject = new BehaviorSubject<IBiz | undefined | null>(null);
  public bizTmp = this.bizTmpSubject.asObservable().pipe(distinctUntilChanged());

  public listBizSubject = new BehaviorSubject<IBiz[]>([] as IBiz[]);
  public listBiz = this.listBizSubject.asObservable().pipe(distinctUntilChanged());

  public listRolesSubject = new BehaviorSubject<IBizUserRole[]>([] as IBizUserRole[]);
  public listRoles = this.listRolesSubject.asObservable().pipe(distinctUntilChanged());

  public userRolesBizSubject = new BehaviorSubject<IBizUserRole | null>(null);
  public userRolesBiz = this.userRolesBizSubject.asObservable().pipe(distinctUntilChanged());

  // danh sách page mà user có quyền trong livechat
  public listPageChatByRoleSubject = new BehaviorSubject<any | null>(null);
  public listPageChatByRole = this.listPageChatByRoleSubject.asObservable().pipe(distinctUntilChanged());

  // danh sách page mà user có quyền trong biz
  public listPageByRoleSubject = new BehaviorSubject<string[] | null>([]);
  public listPageByRole = this.listPageByRoleSubject.asObservable().pipe(distinctUntilChanged());

  public listDomainSubject = new BehaviorSubject<IDomain[]>([] as IDomain[]);
  public listDomain = this.listDomainSubject.asObservable().pipe(distinctUntilChanged());

  public onUpdateCustomImageEvent: EventEmitter<any> = new EventEmitter<any>();

  constructor(
    private http: HttpClient,
    private sessionService: SessionService,
    private bizGlobalService: BizGlobalService,
    private router: Router,
    private setupDataService: SetupDataService,
  ) {
    super(http);
  }

  bizs = {
    get: (bizAlias: string) => this.http.get<IResponseWrapper<IBiz>>(this.createUrl(['bizs', bizAlias])),
    getAll: () => this.http.get<IResponseWrapper<IBiz[]>>(this.createUrl(['bizs'])),
    addMemberIntoBiz: (bizAlias: string, body: any) =>
      this.http.put<IResponseWrapper<IBiz>>(this.createUrl(['bizs', bizAlias, 'members']), body),
    updateMemberBiz: (
      bizAlias: string,
      memberId: string,
      body: {
        active?: boolean;
        role?: string;
        member_role_ids?: string[];
      },
    ) => this.http.put<IResponseWrapper<IBiz>>(this.createUrl(['bizs', bizAlias, 'members', memberId]), body),
    onRemoveMemberFromBiz: (bizAlias: string, memberId: string) =>
      this.http.delete<IResponseWrapper<IBiz>>(this.createUrl(['bizs', bizAlias, 'members', memberId])),
    createBiz: (body: Partial<IBiz>) =>
      this.http.post<IResponseWrapper<IBiz>>(this.createUrl(['bizs']), body).pipe(
        tap((res) => {
          this.listBizSubject.next([...this.listBizSubject.getValue(), { ...res.data }]);
        }),
      ),
    updateBiz: (alias: string, body: Partial<IBiz>) =>
      this.http.put<IResponseWrapper<IBiz>>(this.createUrl(['bizs', alias]), body),
    bizNoti: (alias: string, userId: string, body: any) =>
      this.http.put<IResponseWrapper<IBiz>>(this.createUrl(['bizs', alias, 'members', userId, 'noti']), body),
    sortModule: (alias: string, body: Partial<IBiz>) =>
      this.http.put<IResponseWrapper<IBiz>>(this.createUrl(['bizs', alias, 'modules']), body),
  };

  domain = {
    getAll: (bizAlias: string) =>
      this.http.get<IResponseWrapper<IDomain[]>>(this.createUrl(['bizs', bizAlias, 'domains'])).pipe(
        tap((res) => {
          this.listDomainSubject.next(res.data);
        }),
      ),
    create: (bizAlias: string, command: Partial<IDomain>) =>
      this.http.post<IResponseWrapper<IDomain>>(this.createUrl(['bizs', bizAlias, 'domains']), command).pipe(
        tap((res) => {
          this.listDomainSubject.next([...this.listDomainSubject.getValue(), { ...res.data }]);
        }),
      ),
    verify: (bizAlias: string, id: string) =>
      this.http.post<IResponseWrapper<IDomain>>(this.createUrl(['bizs', bizAlias, 'domains', id, 'verify']), {}).pipe(
        tap((res) => {
          const updateDomain = this.listDomainSubject.getValue().map((domain) => {
            if (res.data.id === domain.id) {
              return res.data;
            }
            return domain;
          });
          this.listDomainSubject.next(updateDomain);
        }),
      ),
    delete: (bizAlias: string, id: string) =>
      this.http.delete<IResponseWrapper<IDomain>>(this.createUrl(['bizs', bizAlias, 'domains', id])).pipe(
        tap((res) => {
          let listDomains = this.listDomainSubject.getValue();
          const index = listDomains.findIndex((o) => o.id === id);
          if (index !== -1) {
            listDomains.splice(index, 1);
            this.listDomainSubject.next(listDomains);
          }
        }),
      ),
  };

  logs = {
    getAllDs: (bizAlias: string, param?: any) => {
      const stringParam = this.handleParam(param);

      return this.http.get<IResponseWrapper<ISentCard[]>>(this.createUrl(['bizs', bizAlias, 'cards', `logs`]), {
        params: this.createParams(param),
      });
    },
    getBlock: (bizAlias: string, params?: any) => {
      return this.http.get<IResponseWrapper<any[]>>(this.createUrl(['bizs', bizAlias, 'blocks', `logs`]), {
        params,
      });
    },
  };

  userRoles = {
    delete: (bizAlias: string, id: string) =>
      this.http.delete<IResponseWrapper<IBiz>>(this.createUrl(['bizs', bizAlias, 'roles', id])).pipe(
        tap((res) => {
          const roles = res.data.roles || [];
          this.listRolesSubject.next(roles);
        }),
      ),
    create: (bizAlias: string, command: Partial<IBizUserRole>) =>
      this.http.post<IResponseWrapper<IBiz>>(this.createUrl(['bizs', bizAlias, 'roles']), command).pipe(
        tap((res) => {
          const roles = res.data.roles || [];
          this.listRolesSubject.next(roles);
        }),
      ),
    update: (bizAlias: string, id: string, command: Partial<IBizUserRole>) =>
      this.http.put<IResponseWrapper<IBiz>>(this.createUrl(['bizs', bizAlias, 'roles', id]), command).pipe(
        tap((res) => {
          const roles = res.data.roles || [];
          this.listRolesSubject.next(roles);
        }),
      ),
  };

  getAuthor(idUser: string, biz_id: string): IUser | null {
    const list = this.listBizSubject.getValue();
    const currentBiz = list.find((o) => o.id == biz_id);
    if (currentBiz) {
      const index = currentBiz.users?.findIndex((o) => o.id === idUser);
      if (index === -1) return {};
      return currentBiz.users[index];
    }
    return null;
  }

  // BIZ
  setCurrentBiz(biz: IBiz | null) {
    if (biz) {
      const currentUsersBiz: IBizUser =
        biz.users?.find((item) => item.id === this.sessionService.currentAuthUser.id) || {};
      if (biz.user) {
        biz.user.role_biz = currentUsersBiz.role;
      } else {
        biz.user = {
          ...{ role_biz: currentUsersBiz.role },
          ...currentUsersBiz,
        };
      }
      if (!biz?.top_menu_mode) biz.top_menu_mode = 'icon_text';
      if (!biz?.type) biz.type = 'BOT';
      if (!biz?.top_menu_items?.length) {
        biz.top_menu_items =
          biz?.type == 'CHAT'
            ? LisMenuDefaultChat
            : biz?.type == 'BOT'
            ? ListMenuDefaultBot
            : biz?.type == 'ADS'
            ? ListMenuDefaultAds
            : ListMenuDefaultRemarketing;
      }

      this.bizGlobalService.clearData();
      // this.currentBizSubject.next({ ...biz });
      this.bizTmpSubject.next({ ...biz });
      this.setListRoles(biz);
      this.setCurrentRolesUser(biz);
      localStorage.setItem(LOCALSTORAGE.ACTIVE_BIZ, biz?.alias as string);
    } else {
      this.currentBizSubject.next(null);
      this.bizTmpSubject.next(null);
      this.listRolesSubject.next([]);
      this.userRolesBizSubject.next(null);
      localStorage.setItem(LOCALSTORAGE.ACTIVE_BIZ, '');
    }
  }

  setBizTmp(biz: IBiz) {
    this.bizTmpSubject.next(biz);
  }

  setListRoles(bizInfo: IBiz) {
    this.listRolesSubject.next(bizInfo.roles || []);
  }

  isActiveRoleInBiz(biz: IBiz) {
    const arr = ['dashboard', 'livechat', 'chatbot', 'analytic', 'contact', 'setting', 'log'];
    if (biz?.user_roles && !biz?.user_roles?.active) return false;
    else {
      if (!biz?.user_roles) return true;
      // @ts-ignore
      return !!(biz?.user_roles && arr?.filter((o) => biz?.user_roles[o])?.length);
    }
  }

  handelUserRoleInListBiz(bizs: IBiz[]) {
    const listBiz = bizs;
    const currentUser = this.sessionService.currentUserSubject.getValue();

    listBiz.forEach((biz) => {
      const roleIdsOfCurrentUser = biz?.users.find((o: any) => o.id == currentUser?.id)?.member_role_ids;
      biz.user_roles = biz?.roles?.find((o) => roleIdsOfCurrentUser?.includes(o?.id || ''));
    });
    this.listBizSubject.next(listBiz);
  }

  isPermissionBotAuto() {
    const role = this.currentBizSubject.getValue()?.user?.role_biz;
    const user_roles = this.currentBizSubject.getValue()?.user_roles;
    return (
      role === 'ADMIN' ||
      (role === 'MEMBER' &&
        user_roles?.chatbot?.status &&
        (user_roles?.chatbot?.bot_setup || user_roles?.chatbot?.bot_preview))
    );
  }

  isPermissionSetupBotAuto() {
    const role = this.currentBizSubject.getValue()?.user?.role_biz;
    const user_roles = this.currentBizSubject.getValue()?.user_roles;
    return role === 'ADMIN' || (role === 'MEMBER' && user_roles?.chatbot?.status && user_roles?.chatbot?.bot_setup);
  }

  isPermissionSettingsMember() {
    const role = this.currentBizSubject.getValue()?.user?.role_biz;
    const user_roles = this.currentBizSubject.getValue()?.user_roles;
    return {
      role: role,
      active: role === 'ADMIN' || (role === 'MEMBER' && user_roles?.setting?.team),
    };
  }

  isPermissionSettings() {
    const role = this.currentBizSubject.getValue()?.user?.role_biz;
    const user_roles = this.currentBizSubject.getValue()?.user_roles;
    return role === 'ADMIN' || (role === 'MEMBER' && user_roles?.setting?.status);
  }

  isPermissionPackage() {
    const role = this.currentBizSubject.getValue()?.user?.role_biz;
    const user_roles = this.currentBizSubject.getValue()?.user_roles;
    return role === 'ADMIN' || (role === 'MEMBER' && user_roles?.setting?.status && user_roles?.setting?.package);
  }

  isPermissionExtendModule() {
    const role = this.currentBizSubject.getValue()?.user?.role_biz;
    const user_roles = this.currentBizSubject.getValue()?.user_roles;
    return role === 'ADMIN' || (role === 'MEMBER' && user_roles?.extend_module?.status);
  }

  permissionPageId(): { pids: string[] | undefined; active_all_page: false | boolean | undefined } {
    const role = this.currentBizSubject.getValue()?.user?.role_biz;
    const user_roles = this.currentBizSubject.getValue()?.user_roles;
    return {
      pids: user_roles?.page_pids,
      active_all_page: role === 'ADMIN' || (role === 'MEMBER' && user_roles?.apply_all),
    };
  }

  isPermissionChatLeaders(pid: string, user_id: string) {
    const bizInfo = this.currentBizSubject.getValue();
    const targetUser = bizInfo?.users.find((o: any) => o.id == user_id);
    const user_roles = bizInfo?.roles?.find((o) => targetUser?.member_role_ids?.includes(o?.id || ''));
    return (
      targetUser?.role === 'ADMIN' ||
      (targetUser?.role === 'MEMBER' && user_roles?.livechat?.chat_leaders?.includes(pid))
    );
  }

  isPermissionChatSupports1(pid: string, user_id: string) {
    const bizInfo = this.currentBizSubject.getValue();
    const targetUser = bizInfo?.users.find((o: any) => o.id == user_id);
    const user_roles = bizInfo?.roles?.find((o) => targetUser?.member_role_ids?.includes(o?.id || ''));
    return targetUser?.role === 'MEMBER' && user_roles?.livechat?.chat_supporter1s?.includes(pid);
  }

  isPermissionChatSupports2(pid: string, user_id: string) {
    const bizInfo = this.currentBizSubject.getValue();
    const targetUser = bizInfo?.users.find((o: any) => o.id == user_id);
    const user_roles = bizInfo?.roles?.find((o) => targetUser?.member_role_ids?.includes(o?.id || ''));
    return targetUser?.role === 'MEMBER' && user_roles?.livechat?.chat_supporter2s?.includes(pid);
  }

  setCurrentRolesUser(bizInfo: IBiz) {
    // const bizInfo = this.currentBizSubject.getValue();
    const currentUser = this.sessionService.currentUserSubject.getValue();

    const roleIdsOfCurrentUser = bizInfo?.users.find((o: any) => o.id == currentUser?.id)?.member_role_ids;
    const roleUser = bizInfo?.users.find((o: any) => o.id == currentUser?.id)?.role;
    const role = bizInfo?.roles?.find((o) => roleIdsOfCurrentUser?.includes(o?.id || ''));
    this.currentBizSubject.next({ ...bizInfo, user_roles: role } as IBiz);
    this.userRolesBizSubject.next(role || null);
    this.listPageChatByRoleSubject.next({
      applyAll: role?.apply_all,
      role: roleUser,
      chat_leaders: role?.livechat?.chat_leaders || [],
      chat_supporter1s: role?.livechat?.chat_supporter1s || [],
      chat_supporter2s: role?.livechat?.chat_supporter2s || [],
      generalRole:
        [
          ...(role?.livechat?.chat_leaders || []),
          ...(role?.livechat?.chat_supporter1s || []),
          ...(role?.livechat?.chat_supporter2s || []),
        ] || [],
    });
    this.listPageByRoleSubject.next(role?.page_pids || []);
  }

  handleNavigateLinkWithRole(biz: IBiz) {
    const menus = menuList?.filter((o) => !o.disabled);
    if (!biz || (biz?.user_roles && !biz?.user_roles?.active)) return;

    const currentUser = this.sessionService.currentUserSubject.getValue();
    const roleIdsOfCurrentUser = biz?.users.find((o: any) => o.id == currentUser?.id)?.member_role_ids;
    const user_roles = biz?.roles?.find((o) => roleIdsOfCurrentUser?.includes(o?.id || ''));
    const currentBiz: IBiz = {
      ...biz,
      user_roles: user_roles,
    };
    if (!currentBiz?.user_roles) {
      this.router.navigate([`bizs/${currentBiz.alias}/chats`]);
    } else {
      const index = menus?.findIndex((menu) => {
        const key = menu.roleKey;
        return (
          // @ts-ignore
          (key in currentBiz.user_roles && currentBiz?.user_roles[key]?.status) ||
          // @ts-ignore
          (!(key in currentBiz.user_roles) && key == 'visible')
        );
      });
      if (index !== -1) this.router.navigate([`bizs/${currentBiz.alias}/${menus[index].key}`]);
      else {
        this.router.navigate([`bizs/${currentBiz.alias}/empty-permissions`]);
      }
    }
  }

  setListBiz(bizs: IBiz[]) {
    this.handelUserRoleInListBiz(bizs);
  }

  updateListBiz(updatedBiz: IBiz) {
    const updatedListBiz = this.listBizSubject.getValue().map((biz) => {
      if (biz.id === updatedBiz.id) {
        return updatedBiz;
      }
      return biz;
    });
    this.listBizSubject.next(updatedListBiz);
  }

  // domain
  setListDomain(domain: IDomain[]) {
    this.listDomainSubject.next(domain);
  }

  isExceedsNumberOfCustomer(biz: IBiz): ITypeExceedsPackage {
    const order = biz?.order;
    const usage = (order?.total_customers || 0) / (order?.customers || 0);
    if (!order?.total_customers || !order?.customers || (order?.total_customers && usage < 0.9))
      return { type: 'NONE' } as ITypeExceedsPackage;
    return {
      type: usage >= 0.9 && usage < 1 ? 'WARNING' : 'ERROR',
      exceed_value: `${order?.total_customers || 0}/${order?.customers}`,
      unit: 'customer(s)',
      value: 'customer',
    } as ITypeExceedsPackage;
  }

  isExceedsNumberOfSendCard(biz: IBiz): ITypeExceedsPackage {
    const order = biz?.order;
    const usage = (order?.used_cards || 0) / (order?.cards || 0);
    if (!order?.used_cards || !order?.cards || (order?.used_cards && usage < 0.9))
      return { type: 'NONE' } as ITypeExceedsPackage;
    return {
      type: usage >= 0.9 && usage < 1 ? 'WARNING' : 'ERROR',
      exceed_value: (usage * 100).toFixed(2),
      unit: '%',
      value: 'send_card',
    } as ITypeExceedsPackage;
  }

  isExceedsTimePaymentDeadline(biz: IBiz): ITypeExceedsPackage {
    const order = biz?.order;
    const day_now = dayjs(new Date());
    const day_end = dayjs(order?.time_end);
    const remained = day_end.diff(day_now, 'day');
    if (remained > 5 || !order?.time_end) return { type: 'NONE' } as ITypeExceedsPackage;
    return {
      type: remained <= 5 && remained > 1 ? 'WARNING' : 'ERROR',
      exceed_value: remained,
      unit: 'day(s)',
      value: 'time',
    } as ITypeExceedsPackage;
  }
}
