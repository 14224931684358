import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BaseApiService } from './base.service';
import { Injectable } from '@angular/core';

import { IResponseWrapper } from '@core/models';

import { BehaviorSubject, distinctUntilChanged, tap } from 'rxjs';
import { IOrders, IUpdateOrder } from 'app/models/orders';
import { IBiz } from 'app/models/biz';
import {
  IAffiliate,
  IAffiliateManuals,
  IAffiliatePayments,
  ILevel,
  IPaymentInfo,
} from 'app/models/affiliate';
import { IUser } from 'app/models/users';

@Injectable({
  providedIn: 'root',
})
export class AffiliateService extends BaseApiService {
  constructor(httpClient: HttpClient) {
    super(httpClient);
  }
  private listMyRefsSubject = new BehaviorSubject<IAffiliate[]>(
    [] as IAffiliate[]
  );
  public listMyRefsValue = this.listMyRefsSubject
    .asObservable()
    .pipe(distinctUntilChanged());

  private myCurrentRefSubject = new BehaviorSubject<IAffiliate | null>(null);
  public myCurrentRefValue = this.myCurrentRefSubject
    .asObservable()
    .pipe(distinctUntilChanged());
  refs = {
    getMy: (params?: any) => {
      return this.httpClient.get<IResponseWrapper<IAffiliate[]>>(
        this.createUrl(['affiliates', 'my-affiliates']),
        {
          params: this.createParams(params),
        }
      );
    },
    updateRef: (body: { ref_name: string }) => {
      return this.httpClient.post<IResponseWrapper<IAffiliate>>(
        this.createUrl(['affiliates', 'my-ref']),
        body
      );
    },
    updatePaymentRef: (id: string, body: IPaymentInfo) => {
      return this.httpClient
        .put<IResponseWrapper<IAffiliate>>(
          this.createUrl(['affiliates', 'payment', id]),
          body
        )
        .pipe(
          tap((res) => {
            this.setMyCurrentRef(res.data);
          })
        );
    },
    getByIds: (body?: any) => {
      return this.httpClient.post<IResponseWrapper<IAffiliate[]>>(
        this.createUrl(['affiliates', 'by-ids']),
        body
      );
    },
    getByRefName: (refName: string) => {
      return this.httpClient.get<IAffiliate>(
        this.createUrl(['affiliates', refName])
        
      );
    },
    create: (body: IAffiliate) => {
      return this.httpClient.post<IAffiliate>(
        this.createUrl(['affiliates']),
        body
      );
    },
  };
  level = {
    getCurrentLevel: (body?: any) => {
      return this.httpClient.post<ILevel>(
        this.createUrl(['affiliates', 'levels', 'get-level']),
        body
      );
    },
  };
  users = {
    getMyRefUsers: () => {
      return this.httpClient.get<IResponseWrapper<IUser[]>>(
        this.createUrl(['affiliates', 'my-ref-users'])
      );
    },
  };
  payment = {
    getMe: () => {
      return this.httpClient.get<IAffiliatePayments>(
        this.createUrl(['affiliates', 'payments', 'me'])
      );
    },
    create: (body: IAffiliatePayments) => {
      return this.httpClient.post<IAffiliatePayments>(
        this.createUrl(['affiliates', 'payments', 'me']),
        body
      );
    },
  };
  inspect = {
    gets: (params?: any) => {
      return this.httpClient.get<IResponseWrapper<ILevel[]>>(
        this.createUrl(['affiliates', 'inspects']),
        { params: this.createParams(params) }
      );
    },
  };
  manual = {
    getActive: (type: string) => {
      return this.httpClient.get<IResponseWrapper<IAffiliateManuals>>(
        this.createUrl(['affiliates', 'manuals', 'active', type])
      );
    },
  };
  setListMyRefs(myRefs: IAffiliate[]) {
    this.listMyRefsSubject.next(myRefs);
  }
  setMyCurrentRef(myRef: IAffiliate) {
    this.myCurrentRefSubject.next(myRef);
  }
}
