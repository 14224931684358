import {inject} from '@angular/core';
import {Router} from '@angular/router';
import {AuthService} from "../../services/auth.service";

export const loginGuard = () => {
  const authService = inject(AuthService);
  const router = inject(Router);

  if (!authService.checkLogin()) {
    return true;
  }

  // Redirect to the login page
  return router.navigate(['/bizs']);
};
