import { Injectable } from '@angular/core';
import { BaseApiService } from '../base.service';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, distinctUntilChanged, map } from 'rxjs';
import { IIntegration } from '../../models/integration';
import { IResponseWrapper } from '@core/models';
import { tap } from 'rxjs/operators';
import { IBizProduct } from 'app/models/product';
import { Utils } from '@core/utils';

@Injectable({
  providedIn: 'root',
})
export class IntegrationsService extends BaseApiService {
  constructor(private http: HttpClient) {
    super(http);
  }

  public listIntegrationSubject = new BehaviorSubject<IIntegration[]>(
    [] as IIntegration[]
  );
  public listIntegration = this.listIntegrationSubject
    .asObservable()
    .pipe(distinctUntilChanged());

  public productsSubject = new BehaviorSubject<IBizProduct[]>(
    [] as IBizProduct[]
  );
  public products = this.productsSubject
    .asObservable()
    .pipe(distinctUntilChanged());

  public selectedProductsSubject = new BehaviorSubject<IBizProduct[]>(
    [] as IBizProduct[]
  );
  public selectedProducts = this.selectedProductsSubject
    .asObservable()
    .pipe(distinctUntilChanged());

  public selectedTempProductsSubject = new BehaviorSubject<IBizProduct[]>(
    [] as IBizProduct[]
  );
  public selectedTempProducts = this.selectedTempProductsSubject
    .asObservable()
    .pipe(distinctUntilChanged());

  getAll(bizAlias: string) {
    return this.http
      .get<IResponseWrapper<IIntegration[]>>(
        this.createUrl(['bizs', bizAlias, 'integrations?sort=created_at'])
      )
      .pipe(
        tap((res) => {
          // mac dinh co smax app
          const data = res.data;
          this.listIntegrationSubject.next(
            Utils.orderBy(data, ['created_at'], ['desc'])
          );
        })
      );
  }

  getIntegrationInfo(bizAlias: string, id: string, type: string) {
    // const stringParam = this.handleParam(type);
    return this.http.get<IResponseWrapper<IIntegration[]>>(
      this.createUrl([
        'bizs',
        bizAlias,
        'integrations',
        id,
        `info?type=${type}`,
      ])
    );
  }

  delete(bizAlias: string, id: string) {
    return this.http
      .delete<IResponseWrapper<IIntegration>>(
        this.createUrl(['bizs', bizAlias, 'integrations', id])
      )
      .pipe(
        tap((res) => {
          this.removeEntity(this.listIntegrationSubject, id);
        })
      );
  }

  create(bizAlias: string, command: IIntegration) {
    return this.http
      .post<IResponseWrapper<IIntegration>>(
        this.createUrl(['bizs', bizAlias, 'integrations']),
        command
      )
      .pipe(
        tap((res) => {
          this.updateEntity(this.listIntegrationSubject, res.data, 'id', true);
        })
      );
  }

  testIntegration(bizAlias: string, id_integration: string, command: IIntegration) {
    return this.http.post<IResponseWrapper<IIntegration>>(
      this.createUrl(['bizs', bizAlias, 'integrations', id_integration, 'test']),
      command
    );
  }

  update(bizAlias: string, id: string, command: IIntegration) {
    return this.http
      .put<IResponseWrapper<IIntegration>>(
        this.createUrl(['bizs', bizAlias, 'integrations', id]),
        command
      )
      .pipe(
        tap((res) => {
          this.updateEntity(this.listIntegrationSubject, res.data);
        })
      );
  }

  getProductPrm(bizAlias: string, id: string, param?: any) {
    const stringParam = this.handleParam(param);
    return this.http.get<IResponseWrapper<any[]>>(
      this.createUrl([
        'bizs',
        bizAlias,
        'integrations',
        id,
        `products?${stringParam}`,
      ])
    );
  }

  setListIntegration(integrations: IIntegration[]) {
    this.listIntegrationSubject.next(integrations);
  }

  setTempProducts(products: IBizProduct[]) {
    this.selectedTempProductsSubject.next(products);
  }

  setProducts(products: IBizProduct[]) {
    this.productsSubject.next(products);
  }
}
