import { Injectable } from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {interval, map, Observable, switchMap} from "rxjs";
import {MainService} from "../main.service";
import {ToastrService} from "ngx-toastr";

@Injectable({
  providedIn: 'root'
})
export class VersionCheckService {

  private versionUrl = 'assets/version.json';
  private currentVersion: string;
  constructor(
    private http: HttpClient,
    private toast: ToastrService
  ) { }

  checkForNewVersion() {
    // Kiểm tra phiên bản mỗi 5s
    const subscription = interval(5000).pipe(
      switchMap(() => this.getVersionInfo())
    ).subscribe(version => {
      if(this.currentVersion && this.currentVersion == version) subscription.unsubscribe();
      if (this.currentVersion && this.currentVersion !== version) {
        this.showUpdateNotification(version);
      }
      this.currentVersion = version;
    });
  }

  private getVersionInfo(): Observable<string> {
    return this.http.get<{ version: string }>(this.versionUrl).pipe(
      map(response => response.version)
    );
  }

  private showUpdateNotification(version) {
    this.toast.info('New version is now available!', `New version ${version}`, {
      closeButton: false,
      toastClass: 'bg-white shadow py-2 px-4',
      titleClass: 'fs-v14 text-primary-500',
      messageClass: 'fs-v12',
      positionClass: 'toast-bottom-right',
      progressBar: true,
      progressAnimation: 'decreasing',
    })
  }

}
