import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import { HttpClient, HttpParams } from '@angular/common/http';
import { BehaviorSubject } from 'rxjs';
import { Utils } from '@core/utils';

@Injectable({
  providedIn: 'root',
})
export class BaseApiService {
  private apiAddress = environment.baseUrl;

  constructor(protected httpClient: HttpClient) {}

  protected createParams(params: { [key: string]: any }): HttpParams {
    if (!params) {
      // @ts-ignore
      return {}, new HttpParams();
    }
    return Object.keys(params).reduce((m, k) => {
      if (params[k] != null) {
        if (Array.isArray(params[k])) {
          for (const value of params[k]) {
            m = m.append(k, value.toString());
          }
          return m;
        } else {
          return m.set(k, params[k].toString());
        }
      }
      return m;
    }, new HttpParams());
  }

  protected createUrl(paths: string[]) {
    return this.apiAddress + '/' + paths.join('/');
  }

  handleParam(param: any) {
    let stringParam = '';
    if (!param) return '';
    Object.keys(param).forEach((key, index) => {
      if (param[key] !== null) {
        stringParam += index == 0 ? `${key}=${param[key]}` : `&${key}=${param[key]}`;
      }
    });
    return stringParam;
  }

  handleParamParseArrayToObject(param: any) {
    let stringParam = '';
    if (!param) return '';
    Object.keys(param).forEach((key, index) => {
      let stringNew = '';
      if (Array.isArray(param[key])) {
        param[key]?.forEach((p) => {
          stringNew += `&${key}=${p}`;
        });
      }
      stringParam += stringNew ? stringNew : index == 0 ? `${key}=${param[key]}` : `&${key}=${param[key]}`;
    });
    return stringParam;
  }

  // protected updateEntity(
  //   listEntity: BehaviorSubject<any>,
  //   valueUpdate: any,
  //   key = 'id',
  //   isArrayTop = false,
  //   sortKey?: string
  // ) {
  //   const list = listEntity.getValue();
  //   const index = list.findIndex((o: any) => o[key] === valueUpdate[key]);
  //   let dataUpdate
  //   if (index !== -1) {
  //     list[index] = { ...list[index], ...valueUpdate };
  //     if (valueUpdate.weight) {
  //       listEntity.next(
  //         [...list].sort((a, b) => (a.weight ?? 0) - (b.weight ?? 0))
  //       );
  //     } else {
  //       listEntity.next([...list]);
  //     }
  //   } else {
  //     listEntity.next([...list, ...[valueUpdate]]);
  //     if (isArrayTop) {
  //       listEntity.next([...[valueUpdate], ...list]);
  //     } else {
  //       if (valueUpdate.weight) {
  //         listEntity.next(
  //           [...list, ...[valueUpdate]].sort(
  //             (a, b) => (a.weight ?? 0) - (b.weight ?? 0)
  //           )
  //         );
  //       } else {
  //         listEntity.next([...list, ...[valueUpdate]]);
  //       }
  //     }
  //   }
  // }

  protected updateEntity(
    listEntity: BehaviorSubject<any>,
    valueUpdate: any,
    key = 'id',
    isArrayTop = false,
    sortKeyTime?: string,
  ) {
    let dataUpdate = listEntity.getValue();
    const index = dataUpdate.findIndex((o: any) => o[key] === valueUpdate[key]);
    if (index !== -1) {
      dataUpdate[index] = { ...dataUpdate[index], ...valueUpdate };
    } else {
      if (isArrayTop) {
        dataUpdate = [...[valueUpdate], ...dataUpdate];
      } else {
        dataUpdate = [...dataUpdate, ...[valueUpdate]];
      }
    }
    if (valueUpdate.weight) {
      dataUpdate = dataUpdate.sort((a, b) => (a.weight ?? 0) - (b.weight ?? 0));
    }
    if (sortKeyTime) {
      dataUpdate = dataUpdate?.sort(
        (a, b) => new Date(b[sortKeyTime]!).getTime() - new Date(a[sortKeyTime]!).getTime(),
      );
    }
    // dataUpdate = Utils.mergeOneArraysBySameID(dataUpdate, key);
    listEntity.next([...dataUpdate]);
  }

  protected removeEntity(listEntity: BehaviorSubject<any>, value: any, key = 'id') {
    const list = listEntity.getValue();
    const index = list.findIndex((o: any) => o[key] === value);
    if (index !== -1) {
      list.splice(index, 1);
      listEntity.next([...list]);
    }
  }

  protected removeMutiEntity(listEntity: BehaviorSubject<any>, value: string[], key = 'id') {
    let list = listEntity.getValue();
    list = list.filter((a) => !value.includes(a[key]));
    listEntity.next([...list]);
  }

  protected setListEntity(listEntity: BehaviorSubject<any>, data: any[], key = 'id') {
    listEntity.next(Utils.mergeArraysBySameID(listEntity.getValue(), data, key));
  }
}
