import {
  NavigationCancel,
  NavigationEnd,
  NavigationError,
  NavigationSkipped,
  NavigationStart,
  Router,
} from '@angular/router';
import { Component } from '@angular/core';

@Component({
  selector: 'smax-progress-bar',
  templateUrl: './smax-progress-bar.component.html',
  styleUrls: ['./smax-progress-bar.component.scss'],
})
export class SmaxProgressBarComponent {
  loading = true;
  constructor(private router: Router) {

    this.router.events.subscribe((event) => {
      if (event instanceof NavigationStart) {
        this.loading = true;
      } else if (event instanceof NavigationEnd) {
        this.loading = false;
      } else if (event instanceof NavigationCancel) {
        this.loading = false;
      } else if (event instanceof NavigationError) {
        this.loading = false;
      } else if (event instanceof NavigationSkipped) {
        this.loading = false;
      }
    });
  }
}
