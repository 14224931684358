import { Injectable } from '@angular/core';
import { BaseApiService } from '../base.service';
import { HttpClient } from '@angular/common/http';
import { tap } from 'rxjs/operators';
import {
  BehaviorSubject,
  combineLatest,
  distinctUntilChanged,
  map,
} from 'rxjs';
import {IBlock, IBlockAttr, StatisticBlock} from '../../models/block';
import { IResponseWrapper } from '@core/models';
import { Utils } from '@core/utils';
import { DEFAULT_BLOCK_KEY } from '@page/biz/biz-shared/constants/constants';
import { CardsService } from './cards.service';
import {ICard} from "../../models/card";
import {MainService} from "../main.service";
import {BsModalRef, BsModalService} from "ngx-bootstrap/modal";
import {ConfirmComponent} from "@shared/components/confirm/confirm.component";

@Injectable({
  providedIn: 'root',
})
export class BlocksService extends BaseApiService {
  constructor(
    private http: HttpClient,
    private cardServices: CardsService,
    private mainServices: MainService,
    private modalServices: BsModalService
  ) {
    super(http);
  }

  LIMIT = 1000;
  LIMIT_MAX_ERROR = 5;
  isError = 0;
  listBlockTmp: IBlock[] = [];
  paramBlock = {
    page: 1,
    limit: this.LIMIT
  }

  public isHaveCardChangedNotSaveSubject = new BehaviorSubject<{[key: string]: boolean}>({});
  public isHaveCardChangedNotSave = this.isHaveCardChangedNotSaveSubject.asObservable().pipe(distinctUntilChanged());

  // danh sach card duoc thay doi nhung chua luu
  public listBlockChangesSubject = new BehaviorSubject<{[key: string]: {[key: string]: ICard<any>}}>({});
  public listBlockChanges = this.listBlockChangesSubject.asObservable().pipe(distinctUntilChanged());

  public cardUndoSubject = new BehaviorSubject<ICard<any> | null>(null);
  public cardUndo = this.cardUndoSubject.asObservable().pipe(distinctUntilChanged());

  public listBlockSubject = new BehaviorSubject<IBlock[]>([]);
  public listBlock = this.listBlockSubject.asObservable().pipe(distinctUntilChanged());

  public activeBlockSubject = new BehaviorSubject<IBlock | null>(null);
  public activeBlock = this.activeBlockSubject.asObservable().pipe(distinctUntilChanged());

  public activeBlockModalSubject = new BehaviorSubject<string | null>(null);
  public activeBlockModal = this.activeBlockModalSubject.asObservable().pipe(distinctUntilChanged());

  //danh sach modal block detail duoc mở
  public listOfOpenBlockIdSubject = new BehaviorSubject<string[]>([]);
  public listOfOpenBlockId = this.listOfOpenBlockIdSubject.asObservable().pipe(distinctUntilChanged());

  groupBlocksByGroupId$ = this.listBlockSubject.pipe(
    map((blocks) => {
      // @ts-ignore
      return Utils.groupById(blocks, 'group_id', DEFAULT_BLOCK_KEY);
    })
  );

  attrsByBlockId$ = this.listBlockSubject.pipe(
    map((blocks) => {
      return blocks?.reduce((a, v) => ({ ...a, [v.id]: v?.attrs ?? []}), {}) ;
    }))

  setActiveBlock(block: IBlock | null) {
    this.activeBlockSubject.next(block);
  }

  addListOfOpenBlock(blockId?: string) {
    if(blockId) {
      const list = this.listOfOpenBlockIdSubject.getValue() ?? [];
      list.push(blockId);
      this.listOfOpenBlockIdSubject.next(list);
    }
  }

  removeBlockIdOfList() {
    let list = this.listOfOpenBlockIdSubject.getValue()?.slice(0, -1) || [];
    this.listOfOpenBlockIdSubject.next(list as string[]);
  }

  setActiveBlockModal(blockId: string | null) {
    this.activeBlockModalSubject.next(blockId);
  }

  getAll(bizAlias: string, reset: boolean = false) {
    if(reset) {
      this.paramBlock = {page: 1, limit: this.LIMIT};
      this.listBlockSubject.next([]);
      this.listBlockTmp = [];
      this.isError = 0;
    }
    this.getAllBlock(bizAlias).subscribe({
      next: res => {
        if(!res.data?.length) {
          this.listBlockSubject.next(this.listBlockTmp);
          return;
        }
        else if(res.data.length >= this.paramBlock.limit) {
          this.paramBlock.page = this.paramBlock.page + 1;
          this.getAll(bizAlias);
        } else {
          this.listBlockSubject.next(this.listBlockTmp);
        }
      },
      error: err => {
        this.isError++;
        if(this.isError > this.LIMIT_MAX_ERROR) return;
        this.paramBlock.page = this.paramBlock.page + 1;
        this.getAll(bizAlias);
      }
    })
  }

  getAllBlock(bizAlias: string) {
    return this.http
        .get<IResponseWrapper<IBlock[]>>(
            this.createUrl(['bizs', bizAlias, `blocks?page=${this.paramBlock.page}&limit=${this.paramBlock.limit}`])
        )
        .pipe(tap((res) => {
          if(res.data) {
            this.listBlockTmp = [...this.listBlockTmp,...res.data];
            // this.listBlockSubject.next([...this.listBlockSubject.getValue(), ...res.data])
          }
        }));
  }

  getById(bizAlias: string, blockId: string) {
    return this.http.get<IResponseWrapper<IBlock>>(
      this.createUrl(['bizs', bizAlias, 'blocks', blockId])
    );
  }

  create(bizAlias: string, body: any) {
    return this.http
      .post<IResponseWrapper<IBlock>>(
        this.createUrl(['bizs', bizAlias, 'blocks']),
        body
      )
      .pipe(
        tap((res) => {
          this.listBlockSubject.next([
            ...this.listBlockSubject.getValue(),
            { ...res.data },
          ]);
        })
      );
  }

  clone(bizAlias: string, body: Partial<IBlock>) {
    return this.http
      .post<IResponseWrapper<IBlock>>(
        this.createUrl(['bizs', bizAlias, 'blocks', 'clone']),
        body
      )
      .pipe(
        tap((res) => {
          this.listBlockSubject.next([
            ...this.listBlockSubject.getValue(),
            { ...res.data },
          ]);
          this.cardServices.setListCards([
            ...this.cardServices.listCardsSubject.getValue(),
            ...(res.data.cards as ICard<any>[])
          ] as ICard<any>[])
        })
      );
  }

  update(bizAlias: string, blockId: string, body: Partial<IBlock>) {
    return this.http
      .put<IResponseWrapper<IBlock>>(
        this.createUrl(['bizs', bizAlias, 'blocks', blockId]),
        body
      )
      .pipe(
        tap((res) => {
          const updateBlock = this.listBlockSubject.getValue().map((block) => {
            if (block.id === blockId) {
              return res.data;
            }
            return block;
          });
          this.listBlockSubject.next(updateBlock);
        })
      );
  }

  delete(bizAlias: string, blockId: string) {
    return this.http
      .delete<IResponseWrapper<IBlock>>(
        this.createUrl(['bizs', bizAlias, 'blocks', blockId])
      )
      .pipe(
        tap((res) => {
          this.removeEntityBlockById(blockId);
        })
      );
  }

  statistic(bizAlias: string, blockIds: string[]) {
    return this.http.get<IResponseWrapper<StatisticBlock[]>>(
      this.createUrl(['bizs', bizAlias, 'statistics', `blocks?ids=${blockIds?.join(',')}`])
    ).pipe(
      tap((res) => {
        if(res.data) {
          // this.updateStatisticBlocks(res.data);
        }
      })
    );
  }

  getAllStatistic(alias: string, blocks: IBlock[]) {
    if(!blocks?.length) return;
    const idsBlock = blocks?.map(o => o.id);

    this.statistic(alias, idsBlock).subscribe({
      next: statistics => {
        if(statistics.data) {
          // @ts-ignore
          const obj = statistics.data.reduce((a, v) => ({ ...a, [v.block_id]: v}), {});
          const updateBlock = this.listBlockSubject.getValue().map((block) => {
            return {
              ...block,
              statistics: (block.id in obj) && obj[block.id] ? obj[block.id] : block?.statistics ? block?.statistics : {sent: 0} as StatisticBlock
            }
          });
          this.listBlockSubject.next(updateBlock);
        }
      },
      error: err => {
      }
    });
  }

  removeEntityBlockById(block_id: string) {
    let listCards = this.listBlockSubject.getValue();
    const index = listCards.findIndex((o) => o.id === block_id);
    if (index !== -1) {
      listCards.splice(index, 1);
      this.listBlockSubject.next(listCards);
    }
  }

  setListBlock(blocks: IBlock[]) {
    this.listBlockSubject.next(blocks);
  }

  isDeletedBlock(blockId?: string) {
    return this.listBlockSubject.getValue()?.filter(o => o.id == blockId)?.length < 1;
  }

  getCount() {
    const currentListBlock = this.listBlockSubject.getValue();

    if (currentListBlock) {
      return currentListBlock.length;
    }
    return 0;
  }

  getEntityBlock(id: string): IBlock | null {
    const index = this.listBlockSubject.getValue()?.findIndex(o => o.id == id);
    return index !== -1 ? this.listBlockSubject.getValue()[index] : null;
  }


  // Xử lý bật modal confirm nếu ng dùng chỉnh sửa 1 số card cần action Save ở card
  confirmModalRef?: BsModalRef;
  confirmResolve?: () => void;
  confirmReject?: () => void;
  confirmPromise?: Promise<void>;

  handelCloseInterceptorModalBlock(block_id: string): Promise<void> {
    this.confirmPromise = new Promise((resolve, reject) => {
      this.confirmResolve = resolve;
      this.confirmReject = reject;
    });

    if(!this.isHaveCardChangedNotSaveSubject.getValue()[block_id]) {
      // modal.hide();
      if (this.confirmResolve) {
        this.confirmResolve();
      }
    } else {
      this.confirmModalRef = this.modalServices.show(ConfirmComponent, {
        ignoreBackdropClick: true,
        initialState: {
          type: "WARNING",
          title: this.mainServices.getTranslate('confirm.title.are_you_abort_card_save'),
          text: this.mainServices.getTranslate('confirm.sub_title.are_you_abort_card_save'),
          textConfirm: this.mainServices.getTranslate('common.button.okText'),
          textCancel: this.mainServices.getTranslate('common.button.cancel')
        }
      });
      this.confirmModalRef.onHidden?.subscribe(res => {
        if(this.confirmModalRef?.content?.confirmed) {
          if (this.confirmResolve) {
            this.confirmResolve();
          }
          this.confirmModalRef?.hide();
          this.removeIsChanges(block_id);
          this.removeIdBlocksCardChanges(block_id);
        } else {
          if (this.confirmReject) {
            this.confirmReject();
          }
          this.confirmModalRef?.hide();
        }
      })
    }
    return this.confirmPromise;
  }

  removeIsChanges(id_block: string) {
    const listIsChanges = this.isHaveCardChangedNotSaveSubject.getValue();
    delete listIsChanges[id_block];
    this.isHaveCardChangedNotSaveSubject.next({...listIsChanges});
  }

  updateIsChanges(id_block: string, value: boolean) {
    const listIsChanges = this.isHaveCardChangedNotSaveSubject.getValue();
    listIsChanges[id_block] = value;
    this.isHaveCardChangedNotSaveSubject.next({...listIsChanges});
  }


  updateListBlocksCardChanges(id_block: string, id_card: string, value: ICard<any>) {
    const listBlockChanges = this.listBlockChangesSubject.getValue();
    if(listBlockChanges[id_block]) {
      listBlockChanges[id_block][id_card] = value;
    } else {
      listBlockChanges[id_block] = { [id_card]: value };
    }
    this.listBlockChangesSubject.next({...listBlockChanges});
  }

  removeIdBlocksCardChanges(id_block: string) {
    const listBlockChanges = this.listBlockChangesSubject.getValue();
    delete listBlockChanges[id_block];
    this.listBlockChangesSubject.next({...listBlockChanges});
  }

  removeIdCardChanges(id_block: string, id_card: string) {
    const listBlockChanges = this.listBlockChangesSubject.getValue();
    delete listBlockChanges[id_block][id_card];
    this.listBlockChangesSubject.next({...listBlockChanges});
  }

}
