import { Injectable } from '@angular/core';
import { BaseApiService } from './base.service';
import { BehaviorSubject, distinctUntilChanged } from 'rxjs';
import { IMarketPlaceTags, ISysModule } from 'app/models/marketplace';
import { HttpClient } from '@angular/common/http';
import { IResponseWrapper } from '@core/models';
import { INews } from 'app/models/news';
import { IBiz } from 'app/models/biz';
import { first, map, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class MarketplaceService extends BaseApiService {
  public listSysModuleSubject = new BehaviorSubject<ISysModule[]>(
    [] as ISysModule[]
  );
  public listSysModule = this.listSysModuleSubject
    .asObservable()
    .pipe(distinctUntilChanged());
  private listNewFeaturedSubject = new BehaviorSubject<ISysModule[]>(
    [] as ISysModule[]
  );
  public listNewFeatured = this.listNewFeaturedSubject
    .asObservable()
    .pipe(distinctUntilChanged());
  private listMarketTagSubject = new BehaviorSubject<IMarketPlaceTags[]>(
    [] as IMarketPlaceTags[]
  );
  public listNewMarketTag = this.listMarketTagSubject
    .asObservable()
    .pipe(distinctUntilChanged());

  constructor(private http: HttpClient) {
    super(http);
  }
  getAll(params: {
    page?: number;
    limit?: number;
    name?: string;
    tag?: string;
  }) {
    return this.http
      .get<IResponseWrapper<ISysModule[]>>(this.createUrl(['marketplaces']), {
        params: this.createParams(params),
      })
      .pipe(
        tap((res) => {
          let dataCategory = res?.data;
          let dataNewAndFeature = res?.data?.filter((o) => o?.is_new_featured);
          this.setListSysModule(dataCategory);
          this.setListNewFeatured(dataNewAndFeature);
        })
      );
  }
  getByAlias(alias: string) {
    return this.http.get<ISysModule>(
      this.createUrl(['marketplaces', 'get-by-alias', alias])
    );
  }
  getTags() {
    return this.http.get<string[]>(this.createUrl(['marketplaces', 'tags']));
  }
  addToBiz(alias: string, nameModule: string) {
    return this.http.post<IResponseWrapper<IBiz>>(
      this.createUrl(['bizs', alias, 'modules']),
      { alias: nameModule }
    );
  }

  getModuleByAlias(alias: string) {
    return this.listSysModuleSubject.getValue()?.find((module) => module.alias === alias);
  }
  removeToBiz(alias: string, nameModule: string) {
    return this.http.delete<IResponseWrapper<IBiz>>(
      this.createUrl(['bizs', alias, 'modules', nameModule])
    );
  }

  setListSysModule(sysModules: ISysModule[]) {
    this.listSysModuleSubject.next(sysModules);
  }
  setListNewFeatured(sysModules: ISysModule[]) {
    this.listNewFeaturedSubject.next(sysModules);
  }
  setListTags(tags: IMarketPlaceTags[]) {
    this.listMarketTagSubject.next(tags);
  }
}
