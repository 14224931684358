import { Injectable } from '@angular/core';
import {HttpRequest, HttpResponse} from "@angular/common/http";

@Injectable({
  providedIn: 'root'
})
export class CatchingInterceptorService {

  constructor() { }

  private cache = new Map<string, { response: HttpResponse<any>, expiry: number, isExpired: boolean }>();

  // Time to live in milliseconds (5s)
  private ttl = 5000;

  // Lấy cache cho một request cụ thể
  getCache(request: HttpRequest<any>): HttpResponse<any> | null {
    const url = request.urlWithParams;
    const cached = this.cache.get(url);

    if (!cached) {
      return null;  // Không có cache
    }
    // Kiểm tra nếu cache đã hết hạn
    const isExpired = Date.now() > cached.expiry;
    if (isExpired) {
      // this.cache.set(url, { ...cached, isExpired: true });  // Đánh dấu cache đã hết hạn
      this.invalidateCache(request);  // Hủy cache khi đã hết hạn
      return null;
    }

    return cached.response;
  }

  // Thiết lập cache cho một request
  setCache(request: HttpRequest<any>, response: HttpResponse<any>): void {
    const url = request.urlWithParams;
    const expiry = Date.now() + this.ttl;
    this.cache.set(url, { response, expiry, isExpired: false });
    // Tự động hủy cache sau 5 giây
    setTimeout(() => {
      this.invalidateCache(request);
    }, this.ttl);
  }

  // Kiểm tra xem cache có hết hạn chưa
  isCacheExpired(request: HttpRequest<any>): boolean {
    const url = request.urlWithParams;
    const cached = this.cache.get(url);
    return cached ? cached.isExpired : false;
  }

  // Hủy cache sau khi TTL hết hạn
  invalidateCache(request: HttpRequest<any>): void {
    const url = request.urlWithParams;
    if (this.cache.has(url)) {
      this.cache.delete(url);
    }
  }
}
