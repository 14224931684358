import { Injectable } from '@angular/core';
import {TranslateLoader} from "@ngx-translate/core";
import { HttpClient } from "@angular/common/http";
import {forkJoin, map, Observable} from "rxjs";
import {catchError} from "rxjs/operators";

@Injectable({
  providedIn: 'root'
})
export class CustomLoaderTranslatesService implements TranslateLoader{

  private translationFiles = [
    'common.json',
    'layout.json',
    'bizs.json',
    'bizs-settings.json',
    'bizs-bot.json',
    'bizs-chat.json',
    'bizs-extend.json',
    'bizs-order.json',
    'biz-templates.json',
  ]; // Add more files as needed


  constructor(private http: HttpClient) { }

  isObject(obj: null) {
    return typeof obj === 'object' && obj !== null;
  }

  getTranslation(lang: string): Observable<any> {
    const observables = this.translationFiles.map(file => this.http.get(`./assets/i18n/${lang}/${file}`));
    return forkJoin(observables).pipe(
      map(results => {
        let obj = {};
        results?.forEach(file => {
          for (const key in file) {
            // @ts-ignore
            if (this.isObject(file[key])) {
              // @ts-ignore
              obj = { ...obj, [key]: { ...obj[key], ...file[key] } };
            } else {
              // @ts-ignore
              obj = { ...obj, [key]: file[key] };
            }
          }
        });
        return obj;
      }), // Shallow merge
      catchError(error => {
        console.error('Error loading translation files', error);
        return [];
      })
    );
  }
}
