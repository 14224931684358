import {Injectable} from '@angular/core';
import {BaseApiService} from '../base.service';
import {BehaviorSubject, distinctUntilChanged,} from 'rxjs';
import {ITrigger, TriggerType} from '../../models/trigger';
import { HttpClient } from '@angular/common/http';
import {IResponseWrapper} from '@core/models';
import {tap} from 'rxjs/operators';
import {BlocksService} from "./blocks.service";

@Injectable({
  providedIn: 'root',
})
export class TriggerService extends BaseApiService {
  public listTriggerSubject = new BehaviorSubject<ITrigger[]>([] as ITrigger[]);
  public listTrigger = this.listTriggerSubject.asObservable().pipe(distinctUntilChanged());

  public selectedChannelIdSubject = new BehaviorSubject('');
  public selectedChannelId = this.selectedChannelIdSubject.asObservable().pipe(distinctUntilChanged());

  public activeTriggerSubject = new BehaviorSubject<ITrigger | null>(null);
  public activeTrigger = this.activeTriggerSubject
    .asObservable()
    .pipe(distinctUntilChanged());

  public isOpenCreateTriggerSubject = new BehaviorSubject<{visible: boolean, trigger_id: string | null | undefined, type?: 'add' | 'edit'}>({visible: false, trigger_id: null, type: 'add'});
  public isOpenCreateTrigger = this.isOpenCreateTriggerSubject.asObservable().pipe(distinctUntilChanged());

  constructor(
    private http: HttpClient,
    private blockService: BlocksService
  ) {
    super(http);
  }

  getAll(bizAlias: string, param: any) {
    const stringParam = this.handleParam(param);
    return this.http
      .get<IResponseWrapper<ITrigger[]>>(
        this.createUrl(['bizs', bizAlias, `triggers?${stringParam}`])
      )
      .pipe(
        tap((res) => {
          this.setListTrigger(res.data);
        })
      );
  }

  create(bizAlias: string, body: Partial<ITrigger>) {
    return this.http
      .post<IResponseWrapper<ITrigger>>(
        this.createUrl(['bizs', bizAlias, 'triggers']),
        body
      )
      .pipe(
        tap((res) => {
          // this.setActiveTrigger(res.data);
          const data = this.listTriggerSubject.getValue();
          this.setListTrigger([...data, {...res.data}]);
        })
      );
  }

  clone(bizAlias: string, body: Partial<ITrigger>) {
    return this.http
      .post<IResponseWrapper<ITrigger>>(
        this.createUrl(['bizs', bizAlias, 'triggers']),
        body
      )
      .pipe(
        tap((res) => {
          // this.setActiveTrigger(res.data);
          const data = this.listTriggerSubject.getValue();
          this.setListTrigger([...data, {...res.data}]);
        })
      );
  }

  delete(bizAlias: string, triggerId: string) {
    return this.http
      .delete<IResponseWrapper<ITrigger>>(
        this.createUrl(['bizs', bizAlias, 'triggers', triggerId])
      )
      .pipe(
        tap((res) => {
          this.deleteEntityTrigger(triggerId);
        })
      );
  }

  deleteEntityTrigger(id: string) {
    const triggers = this.listTriggerSubject.getValue();
    const index = triggers?.findIndex((o) => o.id === id);
    if (index !== -1) {
      triggers?.splice(index, 1);
      this.listTriggerSubject.next(triggers);
    }
  }

  update(bizAlias: string, triggerId: string, body: Partial<ITrigger>) {
    return this.http
      .put<IResponseWrapper<ITrigger>>(
        this.createUrl(['bizs', bizAlias, 'triggers', triggerId]),
        body
      )
      .pipe(
        tap((res) => {

          if(!body?.apply_all && !body?.page_pids?.length) {
            this.deleteEntityTrigger(triggerId);
            this.setActiveTrigger(null);
          } else {
            const updatedListTriggers = this.listTriggerSubject
              .getValue()
              .map((trig) => {
                if (trig.id === triggerId) {
                  return res.data;
                }
                return trig;
              });
            this.listTriggerSubject.next(updatedListTriggers);
          }
        })
      );
  }

  getById(bizAlias: string, triggerId: string) {
    return this.http.get<IResponseWrapper<ITrigger>>(
      this.createUrl(['bizs', bizAlias, 'triggers', triggerId])
    );
  }

  setListTrigger(attribute: ITrigger[]) {
    this.listTriggerSubject.next(attribute);
  }

  setSelectedIdChannel(id: string) {
    this.selectedChannelIdSubject.next(id);
  }

  setActiveTrigger(trigger: ITrigger | null) {
    this.activeTriggerSubject.next(trigger);
  }

  getEntityTrigger(id_trigger: string | undefined | null) {
    const data = this.listTriggerSubject.getValue();
    const index = data.findIndex((o) => o.id == id_trigger);
    if (index !== -1) return data[index];
    else return null;
  }

  isTriggerKeyApplyToAllChannel(triggerKey: TriggerType) {
    const index = this.listTriggerSubject.getValue().findIndex((trigger) => {
      return trigger.type == triggerKey && trigger.apply_all && trigger?.active;
    });
    return index !== -1;
  }

  isCheckPageExitsInTriggerSpecial(pageId: string, triggerKey: TriggerType) {
    const triggerSpecial = this.listTriggerSubject
      .getValue()
      .filter((o) => o.type == triggerKey);

    const arrTriggerFind = triggerSpecial.filter((trigger) =>
      trigger.page_pids?.includes(pageId)
    );

    return {
      page_id: pageId,
      is_exist: arrTriggerFind?.length > 0,
      triggers: arrTriggerFind,
    };
  }

  isCheckCatalogExitsInTrigger(catalogIds: string, triggerId?: string) {
      const triggers = this.listTriggerSubject.getValue()
        ?.filter(o => o.type && ['MESSENGER_SHOPPING']?.includes(o.type));
      return triggers?.filter(trigger =>
        (!triggerId || triggerId !== trigger?.id) && (trigger.messenger_shopping?.apply_all_catalogs || trigger.messenger_shopping?.catalog_ids?.includes(catalogIds)));
  }

}
