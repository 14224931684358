import { Injectable } from '@angular/core';
import { BaseApiService } from '../base.service';
import { HttpClient } from '@angular/common/http';
import { TriggerService } from './trigger.service';
import { SequencesService } from './sequences.service';
import { BroadCastService } from './broadcast.services';
import {IntegrationsService} from "./integrations.service";
import {BlocksService} from "./blocks.service";

@Injectable({
  providedIn: 'root',
})
export class BizGlobalService extends BaseApiService {
  constructor(
    private http: HttpClient,
    private triggerServices: TriggerService,
    private sequenceService: SequencesService,
    private broadcastService: BroadCastService,
    private integrationService: IntegrationsService,
    private blockServices: BlocksService,
  ) {
    super(http);
  }


  clearData() {
    this.sequenceService.setActive(null);
    this.triggerServices.setActiveTrigger(null);
    this.broadcastService.setActive(null);
    this.broadcastService.setSelectedIdChannel('');
    this.triggerServices.setSelectedIdChannel('');
    this.sequenceService.setSelectedIdChannel('');
  }

  clearDataOutsideBiz() {
    this.broadcastService.setListBroadCast([]);
    this.sequenceService.setListSequences([]);
    this.triggerServices.listTriggerSubject.next([]);
    this.integrationService.setListIntegration([]);
    this.blockServices.setListBlock([]);
  }
}
