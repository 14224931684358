import {AbstractControl, FormGroup, ValidationErrors, ValidatorFn} from "@angular/forms";
import * as _ from "lodash";

export class Utils {

  static isInvalid(controlName: string, form: FormGroup) {
    const control = form.get(controlName);
    return control?.invalid && (control.touched || control.dirty);
  }

  static urlValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const urlPattern = /^(https?|ftp):\/\/[^\s/$.?#].[^\s]*$/i;
      const isValid = urlPattern.test(control.value);
      return isValid ? null : { invalidUrl: true };  // Trả về lỗi nếu không hợp lệ
    };
  }

  static characterValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const forbidden =  /[!@#$%^&*(),.?":{}|<>[\]\\;/+=`~_-]/g.test(control.value);  // Kiểm tra ký tự không hợp lệ
      return forbidden ? { invalidCharacter: true } : null;    // Trả về lỗi nếu có ký tự lạ
    };
  }

  static getColorGradient(value: string) {
    if(!value) return [];
    else if(value?.includes('linear-gradient')) {
      const cssString = value;
      // Biểu thức regex để lấy giá trị góc
      const anglePattern = /linear-gradient\((\d+deg),/;

      // Biểu thức regex để lấy các giá trị màu
      const colorPattern = /#(([0-9a-fA-F]{2}){3,4}|([0-9a-fA-F]){3,4})/g;

      // Lấy giá trị góc
      const angleMatch = cssString.match(anglePattern);
      const angle = angleMatch ? angleMatch[1] : null;

      // Lấy các giá trị màu
      const colorMatches = cssString.match(colorPattern);
      return colorMatches ? colorMatches.map(color => color) : [];
    }
    return [];
  }

  static orderBy(array: any[], iterates: string[], order: string[]) {
    return _.orderBy(array, iterates, order);
  }

  static arraysAreEqual(array1: any[], array2: any[]): boolean {
    // Check if arrays have the same length
    if (array1?.length !== array2?.length) {
      return false;
    }

    // Iterate through each element and compare values
    for (let i = 0; i < array1?.length; i++) {
      if (array1[i] !== array2[i]) {
        return false;
      }
    }

    // Arrays are equal if they have the same length and all elements are equal
    return true;
  }

  static deepEqualObject(obj1, obj2) {
    if (obj1 === obj2) {
      return true; // They are the same object
    }

    if (typeof obj1 !== 'object' || obj1 === null || typeof obj2 !== 'object' || obj2 === null) {
      return false; // One is not an object or is null
    }

    const keys1 = Object.keys(obj1);
    const keys2 = Object.keys(obj2);

    if (keys1.length !== keys2.length) {
      return false;
    }

    for (let key of keys1) {
      if (!keys2.includes(key)) {
        return false;
      }

      const val1 = obj1[key];
      const val2 = obj2[key];

      // Check if both values are arrays
      if (Array.isArray(val1) && Array.isArray(val2)) {
        if (val1.length !== val2.length) {
          return false; // Arrays are of different lengths
        }

        // Compare arrays element by element
        for (let i = 0; i < val1.length; i++) {
          if (!this.deepEqualObject(val1[i], val2[i])) {
            return false; // Elements are different
          }
        }
      } else if (typeof val1 === 'object' && typeof val2 === 'object') {
        // Recursively compare objects
        if (!this.deepEqualObject(val1, val2)) {
          return false;
        }
      } else {
        // Compare primitive values
        if (val1 !== val2) {
          return false;
        }
      }
    }

    return true;
  }


  static covertTimeCountDown(countDownDate: number) {
    const now = new Date().getTime();
    const distance = countDownDate - now;
    const days = Math.floor(distance / (1000 * 60 * 60 * 24));
    const hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
    const seconds = Math.floor((distance % (1000 * 60)) / 1000);

    return {
      days: days ? (days < 10 ? '0' + days : days) : 0,
      hours: hours ? (hours < 10 ? '0' + hours : hours) : 0,
      minutes: minutes ? (minutes < 10 ? '0' + minutes : minutes) : 0,
      seconds: seconds ? (seconds < 10 ? '0' + seconds : seconds) : 0,
      distance: distance
    }

  }

  static groupById<T extends { [key: string]: any }>(
    array: T[],
    idKey: keyof T,
    groupIdDefault = 'default',

  ): { [key: string]: T[] } {
    return array.reduce((result, item) => {
      const id = item[idKey];
      if (id) {
        if (!result[id]) {
          result[id] = [];
        }
        result[id].push(item);
      } else {
        if (!result[groupIdDefault]) {
          result[groupIdDefault] = [];
        }
        result[groupIdDefault].push(item);
      }
      return result;
    }, {} as { [key: string]: T[] });
  }

  static mergeArraysBySameID<T>(
    array1: T[],
    array2: T[],
    key = 'id'
  ): T[] {
    const mergedMap = new Map<string, T>();

    // Populate the map with items from array1
    for (const item1 of array1) {
      mergedMap.set(item1[key], item1);
    }

    // Override items from array1 with items from array2
    for (const item2 of array2) {
      mergedMap.set(item2[key], item2);
    }

    // Convert the map values back to an array
    return Array.from(mergedMap.values());
  }

  static mergeOneArraysBySameID<T>(
    array: T[],
    key = 'id'
  ): T[] {
    const mergedMap = new Map<string, T>();

    for (const item of array) {
      mergedMap.set(item[key], item);
    }

    return Array.from(mergedMap.values());
  }

  // regex check có chứa URL
  static validateUrlRegex(): string | RegExp {
    return /^(https?:\/\/)?([a-z0-9-]+\.)*[a-z0-9-]+(\.[a-z]{2,})(\/.*)?$/i;
  }

  // regex check không chứa URL
  static doesNotContainURL(): string | RegExp {
    return /(https?:\/\/)?([a-z0-9-]+\.)*[a-z0-9-]+(\.[a-z]{2,})(\/.*)?/i;
  }

  // copy text
  static copyText(text: string) {
    const selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = text;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);
  }

  // xóa dấu trong đoạn text
  static removeSpecialCharacters(str?: string): string {
    if (!str) return ''
    str = str.replace(/à|á|ạ|ả|ã|â|ầ|ấ|ậ|ẩ|ẫ|ă|ằ|ắ|ặ|ẳ|ẵ/g, 'a');
    str = str.replace(/è|é|ẹ|ẻ|ẽ|ê|ề|ế|ệ|ể|ễ/g, 'e');
    str = str.replace(/ì|í|ị|ỉ|ĩ/g, 'i');
    str = str.replace(/ò|ó|ọ|ỏ|õ|ô|ồ|ố|ộ|ổ|ỗ|ơ|ờ|ớ|ợ|ở|ỡ/g, 'o');
    str = str.replace(/ù|ú|ụ|ủ|ũ|ư|ừ|ứ|ự|ử|ữ/g, 'u');
    str = str.replace(/ỳ|ý|ỵ|ỷ|ỹ/g, 'y');
    str = str.replace(/đ/g, 'd');
    str = str.replace(/À|Á|Ạ|Ả|Ã|Â|Ầ|Ấ|Ậ|Ẩ|Ẫ|Ă|Ằ|Ắ|Ặ|Ẳ|Ẵ/g, 'A');
    str = str.replace(/È|É|Ẹ|Ẻ|Ẽ|Ê|Ề|Ế|Ệ|Ể|Ễ/g, 'E');
    str = str.replace(/Ì|Í|Ị|Ỉ|Ĩ/g, 'I');
    str = str.replace(/Ò|Ó|Ọ|Ỏ|Õ|Ô|Ồ|Ố|Ộ|Ổ|Ỗ|Ơ|Ờ|Ớ|Ợ|Ở|Ỡ/g, 'O');
    str = str.replace(/Ù|Ú|Ụ|Ủ|Ũ|Ư|Ừ|Ứ|Ự|Ử|Ữ/g, 'U');
    str = str.replace(/Ỳ|Ý|Ỵ|Ỷ|Ỹ/g, 'Y');
    str = str.replace(/Đ/g, 'D');
    return str?.replace(/[^a-zA-Z0-9 ]/g, '');
  }

  // kiểm tra có undefined và null
  static isNotNil<T>(value: T): value is NonNullable<T> {
    return typeof value !== 'undefined' && value !== null;
  }


  // cắt tên file thành abcd...xyz.png
  static truncateLinkFile(fullStr: string, strLen: number, separator?: string) {

    // @ts-ignore
    if (fullStr?.length <= strLen) return fullStr;

    separator = separator || '...';

    const sepLen = separator.length,
      charsToShow = strLen - sepLen,
      frontChars = Math.ceil(charsToShow / 2),
      backChars = Math.floor(charsToShow / 2);

    return (
      fullStr?.substring(0, frontChars) +
      separator +
      fullStr?.substring(fullStr?.length - backChars)
    );
  }

  static kFormatter(number: number) {
    // @ts-ignore
    return Math.abs(number) > 999 ? Math.sign(number) * ((Math.abs(number) / 1000).toFixed(1)) + 'k' : Math.sign(number) * Math.abs(number)
  }

  //covert size from number to kb, Mb, kb
  static formatNumberToStringSize(num: number): string {
    if (num >= 1000000000) {
      return (num / 1000000000).toFixed(1).replace(/\.0$/, '') + 'B';
    }
    if (num >= 1000000) {
      return (num / 1000000).toFixed(1).replace(/\.0$/, '') + 'M';
    }
    if (num >= 1000) {
      return (num / 1000).toFixed(1).replace(/\.0$/, '') + 'K';
    }
    return num.toString();
  }

  static getHumanSize(size: number) {
    if (!size) return 0;
    let i = Math.floor(Math.log(size) / Math.log(1024));
    // @ts-ignore
    return ((size / Math.pow(1024, i)).toFixed(i == 2 ? 1 : 0) * 1) + " " + ["B", "KB", "MB"][i];
  }

  // đổi màu ký tự text đang tìm kiếm
  static markTextSearch(text: string, searchText: string) {
    const key = this.ignoreUnicode(searchText);
    const indexOfFirst = this.stringNormalize(text?.toLowerCase()).indexOf(
      this.stringNormalize(key?.toLowerCase())
    );

    // @ts-ignore
    const indexOfEnd = indexOfFirst + key?.length;
    if (indexOfFirst == -1) return text;
    return `
        <span>${text.substring(
      0,
      indexOfFirst
    )}<span class='fw-semibold text-primary-600'>${text.substring(
      indexOfFirst,
      indexOfEnd
    )}</span>${text.substring(indexOfEnd, text.length)}</span>`;
  }

  // đổi màu nền ký tự text đang tìm kiếm
  static markBackgroundTextSearch(text?: string | null, searchText?: string | null) {
    if (!text) return;
    if (!searchText) return text;

    const key = this.ignoreUnicode(searchText);
    const indexOfFirst = this.stringNormalize(text?.toLowerCase()).indexOf(
      this.stringNormalize(key?.toLowerCase())
    );
    // @ts-ignore
    const indexOfEnd = indexOfFirst + key?.length;
    if (indexOfFirst == -1) return text;
    return `
        <span>${text.substring(
      0,
      indexOfFirst
    )}<span class='bg-warning-200 text-natural-500'>${text.substring(
      indexOfFirst,
      indexOfEnd
    )}</span>${text.substring(indexOfEnd, text.length)}</span>`;
  }

  // chuẩn hóa unicode để so sánh text
  static stringNormalize = (text?: string, nonSpace = false) => {
    if (!text) return '';
    let returnText = '';
    returnText = text.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
    returnText = returnText.replace(/đ/g, 'd');
    if (nonSpace) returnText = returnText.replace(/[ ]/g, '_');
    return returnText;
  };

  // bỏ qua dấu trong text
  static ignoreUnicode = (str?: string, toLower = true) => {
    if (!str) return str;

    str = str.trim();
    if (toLower) str = str.toLowerCase();
    str = str.replace(/à|á|ạ|ả|ã|â|ầ|ấ|ậ|ẩ|ẫ|ă|ằ|ắ|ặ|ẳ|ẵ/g, 'a');
    str = str.replace(/è|é|ẹ|ẻ|ẽ|ê|ề|ế|ệ|ể|ễ/g, 'e');
    str = str.replace(/ì|í|ị|ỉ|ĩ/g, 'i');
    str = str.replace(/ò|ó|ọ|ỏ|õ|ô|ồ|ố|ộ|ổ|ỗ|ơ|ờ|ớ|ợ|ở|ỡ/g, 'o');
    str = str.replace(/ù|ú|ụ|ủ|ũ|ư|ừ|ứ|ự|ử|ữ/g, 'u');
    str = str.replace(/ỳ|ý|ỵ|ỷ|ỹ/g, 'y');
    str = str.replace(/đ/g, 'd');
    str = str.replace(
      /!|@|%|\^|\*|\(|\)|\+|=|<|>|\?|\/|,|\.|:|;|'|"|&|#|\[|\]|~|$|_/g,
      '-'
    );

    str = str.replace(/-+-/g, '-');
    str = str.replace(/^-+|-+$/g, '');

    return str;
  };

  // Loại bỏ/ thay thế các ký tự khác [a-z] [0-9]
  static cleanString = (str: string) => {
    str = str.trim();
    str = str.replace(/[^a-zA-Z0-9 ]/g, '');
    return str;
  }

  // FORM VALIDATE
  // không có khoảng trắng
  static noWhiteSpaceValidator(control: AbstractControl) {
    const isWhitespace =
      (control.value || '').trim().length === 0 &&
      (control.value || '').indexOf(' ') !== -1 &&
      (control.value || '').length > 2;
    const isValid = !isWhitespace;
    return isValid ? null : { whitespace: true };
  }

  // trim text trong form
  static trimFormProperties(form: FormGroup): FormGroup {
    if (form.value && typeof form.value === 'object') {
      for (const key in form.value) {
        if (form.value.hasOwnProperty.call(form.value, key)) {
          if (typeof form.value[key] === 'string') {
            form.value[key] = form.value[key].trim();
          }
        }
      }
      form.setValue(form.value);
      return form;
    } else {
      return form;
    }
  }

  // covert milliseconds to days-hours-minutes-seconds

  static formatTimeToDhms(milisecond: number) {
    const days = Math.floor(milisecond / (24 * 60 * 60 * 1000));
    const daysms = milisecond % (24 * 60 * 60 * 1000);
    const hours = Math.floor(daysms / (60 * 60 * 1000));
    const hoursms = milisecond % (60 * 60 * 1000);
    const minutes = Math.floor(hoursms / (60 * 1000));
    const minutesms = milisecond % (60 * 1000);
    const sec = Math.floor(minutesms / 1000);
    return { days, hours, minutes, sec }
  }

  static isPhoneNumber(phone: string, location = 'vn') {
    return !!Utils.formatPhone(phone, location);
  };

  static formatPhone(text: string, location = 'vn') {
    if (!text) return null;
    let check = true;
    let phoneNumber = typeof text === 'string' ? text.replace(/^\+84/, '0').replace(/[^0-9]/g, '') : text;
    if (typeof phoneNumber.toString === 'function') {
      phoneNumber = phoneNumber.toString();
    }
    // kiem tra chieu dai
    if (location === 'vn') {
      // loại bỏ đầu số +84 => 0
      if (phoneNumber.length >= 11) {
        phoneNumber = phoneNumber.replace(/^\+?84/, '0');
      }
      const dauso = phoneNumber.substring(0, 2);
      if (phoneNumber.length < 10 || phoneNumber.length > 11) {
        check = false;
      } else if (phoneNumber[0] !== '0') {
        check = false;
      } else if ((dauso === '01' || dauso === '02') && (phoneNumber.length < 10 || phoneNumber.length > 11)) {
        check = false;
      } else if (dauso !== '01' && dauso !== '02' && phoneNumber.length !== 10) {
        check = false;
      }
    }
    if (check) {
      return phoneNumber;
    } else {
      return null;
    }
  };
}
