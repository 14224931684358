import {Component, ElementRef, HostListener, OnInit, ViewChild, inject, OnDestroy} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { NotificationService } from './services/notification.service';
import {SessionService} from "./services/session.service";
import {filter, Subject, Subscription, takeUntil} from "rxjs";
import {IUser} from "./models/users";
import {UserService} from "./services/user.service";
import {MainService} from "./services/main.service";
import {LOCALSTORAGE} from "@core/constants/constant";
import { ToastrService } from 'ngx-toastr';
import {Utils} from "@core/utils";
import {NavigationEnd, Router} from "@angular/router";
import {MenusService} from "./services/bizs/menus.service";
import { SocketService } from './services/socket.service';
import { environment } from '@environments/environment';
import { FacebookService } from './services/facebook/facebook.service';
import {VersionCheckService} from "./services/app/version-check.service";
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {
  @ViewChild('clickSound', { static: true }) clickSound?: ElementRef
  destroy = new Subject();
  currentUser?: IUser;

  private history: string[] = [];
  private routerEventsSubscription?: Subscription;

  constructor(
    private translate: TranslateService,
    private sessionService: SessionService,
    private userService: UserService,
    private mainServices: MainService,
    private toastr: ToastrService,
    private router: Router,
    private menuServices: MenusService,
    private facebookService: FacebookService,
    private socketService: SocketService,
    private versionCheckServices: VersionCheckService
  ) {

    this.initSdkFb();
    // this language will be used as a fallback when a translation isn't found in the current language
    // this.translate.setDefaultLang('vi');
    // the lang to use, if the lang isn't available, it will use the current loader to get them
    // this.translate.use('vi');

    this.sessionService.currentUser.pipe(takeUntil(this.destroy)).subscribe(res => {
      if (res?.access_token) {
        this.socketService.connected(res.access_token);
      }
      if(res) {
        this.destroy.next(true);
        this.destroy.complete();
        this.currentUser =  res;
        this.initPushBrowser();
      }
    })

    const lang = localStorage.getItem(LOCALSTORAGE.LANGUAGE);
    if (lang && ['vi', 'en'].includes(lang)) {
      this.translate.use(lang);
      this.translate.setDefaultLang(lang);
    } else {
      this.translate.setDefaultLang('en');
      this.translate.use('en');
    }

  }
  ngOnInit() {
    this.listenEventNavigateRouter();
    // this.versionCheckServices.checkForNewVersion();
  }

  initSdkFb() {
    (window as any).fbAsyncInit = () => {
      this.facebookService.init(
        {
          appId: environment.facebook.APP_ID,
          cookie: true,
          xfbml: true,
          version: 'v19.0',
        }
      )
    };
    const script = document.createElement('script');
    script.src = 'https://connect.facebook.net/en_US/sdk.js';
    script.async = true;
    script.defer = true;
    script.crossOrigin = 'anonymous';
    document.body.appendChild(script);
  }

  listenEventNavigateRouter() {
    this.routerEventsSubscription = this.router.events
      .pipe(filter(event => event instanceof NavigationEnd))  // @ts-ignore
      .subscribe((event: NavigationEnd) => {
        this.history.push(event.urlAfterRedirects);
      });
    // Lắng nghe sự kiện popstate
    window.addEventListener('popstate', this.onPopState);
  }

  private onPopState = (event: PopStateEvent): void => {
    event.preventDefault();
    this.menuServices.setBackNavigation(true);
    if(this.history?.length > 0) {
      this.menuServices.setMenuActiveByPath(this.history[this.history?.length - 2]);
      this.router.navigate([this.history[this.history?.length - 2]])
    }
  };

  @HostListener('click', ['$event'])
  onClick(e: MouseEvent) {
    const target = e.target as HTMLElement;
    if (target && target.classList && target.classList.contains('copyText')) {
      Utils.copyText(target?.textContent ?? '')
      this.toastr.info(`Copy [${target.textContent}] to clipboard success!`)
      this.clickSound?.nativeElement.play()
    }
  }

  initPushBrowser() {
    if (this.currentUser) {

      if ('serviceWorker' in navigator) {
        navigator.serviceWorker.register('/sw.js').then((registration) => {

          if (!('showNotification' in ServiceWorkerRegistration.prototype)) {
            console.warn('Notifications aren\'t supported.');
            return;
          }
          if (Notification.permission === 'denied') {
            console.warn('The user has blocked notifications.');
            return;
          }

          if (!('PushManager' in window)) {
            console.warn('Push messaging isn\'t supported.');
            return;
          }
          // registration.update(); //update sw.js
          navigator.serviceWorker.ready.then((serviceWorkerRegistration) => {
            serviceWorkerRegistration.pushManager.getSubscription().then((subscription) => {
              // !this.currentUser.notifications || this.currentUser.notifications.length == 0
              if (!subscription) {
                this.subscribePushBrowser();
                return;
              }
              // kiem tra xem co du dieu khien khong
              this.sendPushBrowserSubscriptionToServer(subscription);
            }).catch(function (err) {
              console.warn('Error during getSubscription()', err);
            });
          });

        })

      } else {
        console.warn('Service workers aren\'t supported in this browser.');
      }
    }
  }

  //Xin quyền thông báo
  subscribePushBrowser() {
    const vapidKeys_publicKey = 'BJbqfuPf7o1G2GiWKlDLbRAWW9hwfPUvd7reLWPx-SxofSCJbE0x1gxSOspZuew8e9glgA9ARf2I9I6X2qC80Dk'
    const convertedVapidKey = this.urlBase64ToUint8Array(vapidKeys_publicKey);

    navigator.serviceWorker.ready.then((serviceWorkerRegistration) => {
      serviceWorkerRegistration.pushManager.subscribe({
        userVisibleOnly: true,
        applicationServerKey: convertedVapidKey
      }).then((subscription) => {
        this.sendPushBrowserSubscriptionToServer(subscription);
      }).catch(function (e) {
        if (Notification.permission === 'denied') {
          console.warn('Permission for Notifications was denied');
        } else {
          console.error('Unable to subscribe to push.', e);
        }
      });
    });
  }

  sendPushBrowserSubscriptionToServer(subscription: PushSubscription) {
    var device: {
      code: String,   // bắt buộc
      keys: {         //bắt buộc đối với web
        p256dh: String,
        auth: String
      }
      type: String // web|android|ios //bắt buộc,
      active: boolean
    };
    // @ts-ignore
    var p256dh = btoa(String.fromCharCode.apply(null, new Uint8Array(subscription.getKey('p256dh'))))
    // @ts-ignore
    var auth = btoa(String.fromCharCode.apply(null, new Uint8Array(subscription.getKey('auth'))))
    device = {
      code: subscription.endpoint,
      keys: {
        p256dh: p256dh,
        auth: auth,
      },
      type: 'web',
      active: true
    }
    this.userService.user_device.create(device).subscribe({
      next: res => {
      },
      error: err => {
        this.mainServices.handleResErr(err);
      }
    });
  }

  urlBase64ToUint8Array(base64String: string | any[]) {
    const padding = '='.repeat((4 - base64String.length % 4) % 4);
    const base64 = (base64String + padding)
      .replace(/\-/g, '+')
      .replace(/_/g, '/');

    const rawData = window.atob(base64);
    const outputArray = new Uint8Array(rawData.length);

    for (let i = 0; i < rawData.length; ++i) {
      outputArray[i] = rawData.charCodeAt(i);
    }
    return outputArray;
  }

  ngOnDestroy() {
    this.destroy.next(true);
    this.destroy.complete();
    if (this.routerEventsSubscription) {
      this.routerEventsSubscription.unsubscribe();
    }
    window.removeEventListener('popstate', this.onPopState);
  }
}
